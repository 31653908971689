<template>
	<div class="switch-parent">
		<div :class="{ 'text-gray': checked }">
			{{ falseName }}
		</div>
		<label class="switch-label">
			<input v-bind="$attrs" class="input" type="checkbox" :checked="checked" @input="inputEvent" />
			<span class="switch"></span>
		</label>
		<div :class="{ 'text-gray': !checked }">
			{{ trueName }}
		</div>
	</div>
</template>
<script>
export default {
	props: {
		falseName: {
			type: String,
			default: "False Name",
		},
		trueName: {
			type: String,
			default: "True Name",
		},
	},
	data: () => ({
		checked: false,
	}),
	methods: {
		inputEvent(e) {
			this.checked = !this.checked;
			this.$emit("input", this.checked);
		},
	},
};
</script>
<style scoped lang="scss">
.text-gray {
	color: #8e9195;
}
.switch-parent {
	display: flex;
	align-items: center;
	justify-content: center;
}
.switch-label {
	--primaryDarkestColor: #374e71;
	--secondaryLighterColor: #e0e0e0ff;
	position: relative;
	min-width: 70px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	.input {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border-width: 0;
	}

	.switch {
		--switch-container-width: 50px;
		--switch-size: calc(var(--switch-container-width) / 2);
		/* Vertically center the inner circle */
		display: flex;
		align-items: center;
		position: relative;
		height: var(--switch-size);
		flex-basis: var(--switch-container-width);
		border-radius: var(--switch-size);
		background-color: var(--secondaryLighterColor);
		flex-shrink: 0;
		transition: background-color 0.25s ease-in-out;

		&::before {
			content: "";
			position: absolute;
			left: 1px;
			height: calc(var(--switch-size) - 4px);
			width: calc(var(--switch-size) - 4px);
			border-radius: 9999px;
			background-color: white;
			border: 2px solid var(--secondaryLighterColor);
			transition: transform 0.375s ease-in-out;
		}
	}

	.input:checked + .switch {
		background-color: var(--primaryDarkestColor);
	}

	.input:checked + .switch::before {
		border-color: var(--primaryDarkestColor);
		/* Move the inner circle to the right */
		transform: translateX(calc(var(--switch-container-width) - var(--switch-size)));
	}

	.input:focus:checked + .switch::before {
		border-color: var(--primaryDarkestColor);
	}
}
</style>

